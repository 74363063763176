import {Group, Table, Tooltip} from '@mantine/core';
import {TimeUsage, EmployeesTimeUsage, EmployeeTimes} from "../pages/Dashboard"
import {Button} from "@mantine/core";
import classes from "./TableEmployeeTimeOverview.module.css"
import ModalComponent from "./ModalComponent";
import {IconAlertCircle, IconUserQuestion} from "@tabler/icons-react"
import {isDateInRange} from "../Helper/HelperFunctions";

interface TimesProps{
    times: EmployeesTimeUsage
    events: EmployeeTimes
    name: string
    dateStart: string
    dateEnd: string
}


    export function translateAmericanToGermanDate(americanDate : string):string{
        const dateParts = americanDate.split('-');
        if (dateParts.length !== 3) {
            return "01.01.1999"; // Invalid date format
        }

        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${day}.${month}.${year}`;
    }

function addTimeSpans(timeSpan1: string, timeSpan2: string): string {
      const time1Parts = timeSpan1.split(':');
      const time2Parts = timeSpan2.split(':');

      if (time1Parts.length !== 3 || time2Parts.length !== 3) {
        return "99:99:99"; // Invalid time format
      }

      const hour1 = parseInt(time1Parts[0], 10);
      const minute1 = parseInt(time1Parts[1], 10);
      const second1 = parseInt(time1Parts[2], 10);

      const hour2 = parseInt(time2Parts[0], 10);
      const minute2 = parseInt(time2Parts[1], 10);
      const second2 = parseInt(time2Parts[2], 10);

      let totalHours = hour1 + hour2;
      let totalMinutes = minute1 + minute2;
      let totalSeconds = second1 + second2;

      if (totalSeconds >= 60) {
        totalMinutes += Math.floor(totalSeconds / 60);
        totalSeconds %= 60;
      }

      if (totalMinutes >= 60) {
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;
      }

      return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;
    }

function TableEmployeeTimeOverview({ times, events, name, dateStart, dateEnd } : TimesProps) {

    function renderError(isError: boolean){
        if(isError){
            return(
                <Group position={"left"}>
                    <Tooltip label={"Stempelsatz fehlerhaft"}>
                    <IconAlertCircle color="red" size={20} stroke={2}/>
                        </Tooltip>
                </Group>
            )
        }
    }

        function renderExplanation(needsExplanation: boolean){
        if(needsExplanation){
            return(
                <Group position={"right"}>
                    <Tooltip label={"Check Out mit Grund"}>
                    <IconUserQuestion color="#228BE6" size={20} stroke={2}/>
                        </Tooltip>
                </Group>
            )
        }
    }



    const relevantTimes : TimeUsage = times[name]
        //console.log(times, name)
    //console.log("relevant", relevantTimes)
    //console.log("relevantTimes", relevantTimes)
    let totalTimeWorking : string = "00:00:00"
    let totalTimeLunch: string = "00:00:00"
    let totalTimeOtherBreak : string = "00:00:00"
    let totalErrors = 0
    const rows = Object.keys(relevantTimes).map((key) => {
        //console.log("date", key)
        if(isDateInRange(new Date(dateStart), new Date(dateEnd), new Date(key))) {

            //console.log("Schlüssel:", key, " Element:", relevantTimes[key])
            //console.log("Total", relevantTimes)
            const element = relevantTimes[key];
            const timeWorking = element.timeWorking
            const timeOtherBreak = element.timeOtherBreak
            const timeLunch = element.timeLunch

            totalTimeOtherBreak = addTimeSpans(totalTimeOtherBreak, timeOtherBreak)
            totalTimeWorking = addTimeSpans(totalTimeWorking, timeWorking)
            totalTimeLunch = addTimeSpans(totalTimeLunch, timeLunch)
            if(element.isError){
                totalErrors+=1
            }
            return (
                <tr key={key}>
                    <td>{translateAmericanToGermanDate(key)}</td>
                    <td>{timeWorking}</td>
                    <td>{timeLunch}</td>
                    <td>{timeOtherBreak}</td>
                    <td>{renderExplanation(element.needsExplanation)}</td>
                    <td>{renderError(element.isError)}</td>
                    <td><ModalComponent events={events} name={name} date={key}/></td>
                    {/*<td><Button onClick={() => clickBtnAlterTimeStamping(key)}>Bearbeiten</Button></td>*/}
                </tr>
            );
        }
    });


    const clickBtnAlterTimeStamping = (date : string) => {
        console.log(events[name][date])
    }


    return (
        <div className={classes.tablediv}>
            <Table highlightOnHover>
                <thead>
                <tr className={classes.thead}>
                    <th className={classes.th}>Datum</th>
                    <th className={classes.th}>Arbeitszeit</th>
                    <th className={classes.th}>Mittagspause</th>
                    <th className={classes.th}>Sonstige Pause</th>
                    <th className={classes.th}></th>
                    <th className={classes.th}></th>
                    <th className={classes.th}></th>
                </tr>
                </thead>
                <tbody>{rows}</tbody>
                <tfoot>
                <tr>
                    <th>Summe:</th>
                    <th>{totalTimeWorking}</th>
                    <th>{totalTimeLunch}</th>
                    <th>{totalTimeOtherBreak}</th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
                </tfoot>
            </Table>
        </div>
    );


}


export default TableEmployeeTimeOverview