import HeaderAction from "./HeaderAction";
import {HeaderLink} from "./HeaderAction";
import classes from "./Layout.module.css"

interface LayoutProps {
  children: React.ReactNode;
}

const exampleLinks: HeaderLink[] = [
  {
    link: '/',
    label: 'Dashboard',
  },
  // {
  //   link: '/einstellungen',
  //   label: 'Profil',
  //   links: [
  //       { link : '/', label : 'Historie' },
  //       { link : '/', label : 'Einstellungen' },
  //   ],
  // },
];


function Layout(props: LayoutProps){

    return (
        <div className={classes.page}>
            <div className={classes.header}>
                <HeaderAction links={exampleLinks}/>
            </div>
            <div className={classes.body}>
                <main>{props.children}</main>
            </div>
        </div>
    )
}

export default Layout