

import React, {ChangeEvent, useState} from 'react';
import {Button, FileInput, Group, Stepper} from '@mantine/core';
import logo from './logo.svg';
import classes from './Dashboard.module.css';
import TableEmployeeTimeOverview, {translateAmericanToGermanDate} from "../components/TableEmployeeTimeOverview";
import DateRangePicker from "../components/DateRangePicker";
import SelectComponent from "../components/SelectComponent";
import * as XLSX from "xlsx";
import {saveAs} from "file-saver";
import {isDateInRange} from "../Helper/HelperFunctions";
import { IconFileTypeCsv } from "@tabler/icons-react"
import { Tabs } from '@mantine/core';
import {IconUsers, IconUser} from '@tabler/icons-react'


    interface SingleDayTimeUsage {
        timeWorking: string;
        timeLunch: string;
        timeOtherBreak: string;
        isError : boolean;
        needsExplanation: boolean
    }


    export interface TimeUsage {
        [date: string] : SingleDayTimeUsage
    }

    export interface EmployeesTimeUsage{
        [employee: string]: TimeUsage
    }

    export interface EventData {
      time: string;
      name: string;
    }

    export interface EmployeeEventTimes {
      [eventDate: string]: EventData[];
    }

    export interface EmployeeTimes {
      [employeeName: string]: EmployeeEventTimes;
    }



function Dashboard() {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [csvAsText, setCsvAsText] = useState("");
    const [wasCalculated, setWasCalculated] = useState(false);
    const [csvInformation, setCsvInformation] = useState<EmployeeTimes | null>(null);
    const [employeeTimes, setEmployeeTimes] = useState<EmployeesTimeUsage | null>(null);
    const [allEmployeeNames, setAllEmployeeNames] = useState<string[] | null>(null)
    const [selectedEmployeeName, setSelectedEmployeeName] = useState<string | null>(null)
    const [selectedDateRangeStart, setSelectedDateRangeStart] = useState<string>("1999-01-01")
    const [selectedDateRangeEnd, setSelectedDateRangeEnd] = useState<string>("2999-01-01")
    const [selectedDateRangeStartAll, setSelectedDateRangeStartAll] = useState<string>("1999-01-01")
    const [selectedDateRangeEndAll, setSelectedDateRangeEndAll] = useState<string>("2999-01-01")


    //Stepper
    const [active, setActive] = useState(0);
    const nextStep = () => setActive((current) => (
        current < 3 ? current + 1 :current));
    const prevStep = () => setActive((current) => (
        current > 0 ? current - 1 :current));

  let employeeNames : string[]  = []



  function calculateTimeDifference(startTime: string, endTime: string): string | null {
      const startTimeParts = startTime.split(':');
      const endTimeParts = endTime.split(':');

      if (startTimeParts.length !== 3 || endTimeParts.length !== 3) {
        return null; // Invalid time format
      }

      const startHour = parseInt(startTimeParts[0], 10);
      const startMinute = parseInt(startTimeParts[1], 10);
      const startSecond = parseInt(startTimeParts[2], 10);

      const endHour = parseInt(endTimeParts[0], 10);
      const endMinute = parseInt(endTimeParts[1], 10);
      const endSecond = parseInt(endTimeParts[2], 10);

      const startDate = new Date();
      startDate.setHours(startHour, startMinute, startSecond);

      const endDate = new Date();
      endDate.setHours(endHour, endMinute, endSecond);

      const timeDifference = endDate.getTime() - startDate.getTime();
      //console.log("Zeitdifferenz", timeDifference)

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }


    let csvReader = new FileReader();

    const handleFileChange = (file: File | null) => {
        setSelectedFile(file);
        // Your additional logic with the selected file
        if (file) {
            //console.log('Selected file:', file);
            setWasCalculated(false)
            setSelectedEmployeeName(null)
        }
    };

    csvReader.addEventListener(
        "load",
        () => {
          // this will then display a text file
            if(wasCalculated){
                window.alert("Die Berechnung wurde bereits durchgeführt.")
                return
            }
            if(typeof csvReader.result === "string"){
                let employeeTimes: EmployeeTimes = {}
                let csvText = csvReader.result
                //console.log("Text", csvText)
                let csvLines = csvText.split("\n")
                //console.log("Arrays", csvLines)
                setCsvAsText(csvReader.result)
                for (let i = 0; i < csvLines.length; i++) {
                  let currentLine = csvLines[i].replace("\"", "");
                  let currentLineValues = currentLine.split(";")
                  if(currentLineValues.length != 5){
                      continue
                  }
                  let employee_name = currentLineValues[2].replace(".", " ");
                  if (employee_name === ""){
                      continue
                  }
                  if(!(employeeNames.includes(employee_name))){
                      employeeNames.push(employee_name)
                  }
                  let event_name = currentLineValues[3];
                  let event_time = currentLineValues[0].split(" ")[1];
                  const event_date = currentLineValues[0].split(" ")[0];
                  //let event = {time:event_time, name:event_name} as events;
                  //let employees = {employee_name: [event]}
                  //console.log("MA:", name, "- Event:", event_name, "- Zeit:", time);
                  employeeTimes[employee_name] ??= {};
                  employeeTimes[employee_name][event_date] ??= [];
                  employeeTimes[employee_name][event_date].push({ time: event_time, name: event_name });
                }
                //console.log("All Employee Names", employeeNames)
                //console.log("Events", employeeTimes)
                setAllEmployeeNames(employeeNames)
                setCsvInformation(employeeTimes)
                setWasCalculated(true)
                //console.log(handleTimes(employeeTimes["Christoph.Brandt"]["2023-01-10"]))
                //console.log(employeeTimes)
                let employeeTimeUsage : EmployeesTimeUsage = {}
                for (const [name, dates] of Object.entries(employeeTimes)) {
                    let dayTimeUsage : TimeUsage = {}
                    for (const [date, events] of Object.entries(dates)) {
                        dayTimeUsage[date] = handleTimes2(events)
                    }
                    employeeTimeUsage[name] = dayTimeUsage
                }
                //console.log("Zeiten für Arbeiter", employeeTimeUsage)
                setEmployeeTimes(employeeTimeUsage)
                nextStep()
            }else{
                window.alert("Die Datei konnte nicht richtig ausgewertet werden. Bitte überprüfe die Datei nocheinmal.")
            }

        },
        false,
      );


    function uploadNewCsv(){
        if(employeeTimes) {
            if (!window.confirm("Achtung. Alle vorgenommenen Einstellungen bezüglich des Mitarbeiters und Zeitraums werden verworfen.")) {
                return
            }
        }
        prevStep()
    }

    function handleTimes2(userEvents: EventData[]){
        const checkInEvents = ["check in & türöffner", "check in"]
        const checkOutEvents = ["mittagspause", "check out", "Check out", "check out mit grund", "check out mit grund", "check out pause", "Check Out Pause"]
        let nextEventIsCheckIn = true;
        let previousEvent = "";
        let previousTime = "";
        let timeWorking : string = "00:00:00";
        let timeLunch: string = "00:00:00";
        let timeOtherBreak: string = "00:00:00"
        let tempTime : string | null;
        let isError : boolean = false;
        let needsExplanation = false;

        for(let i = 0; i < userEvents.length; i++){
            let current_event_time = userEvents[i].time
            let current_event_name = userEvents[i].name.toLowerCase().trim()
            //console.log("Aktuelles Event", current_event_name)
            if(!checkOutEvents.includes(current_event_name) && !checkInEvents.includes(current_event_name)){   //filters irrelevant events like "Türöffner" which is only used to open the door but not relevant for time tracking
                continue
            }
            if(nextEventIsCheckIn){ //Check if next event needs to be a check in
                if(checkInEvents.includes(current_event_name)){
                    if(previousTime === ""){ //If there is no previous time, this has to be the first one

                    }else {
                        let timeBetween = calculateTimeDifference(previousTime, current_event_time)
                        //console.log("Zeit dazwischen:", timeBetween, "Zeiten:", previousTime, current_event_time)
                        if(timeBetween == null){
                            continue
                        }
                        if(previousEvent === "mittagspause"){
                            tempTime = addTimeSpans(timeLunch, timeBetween)
                            if (tempTime == null) {
                                continue
                            }
                            timeLunch = tempTime
                        }else {
                            tempTime = addTimeSpans(timeOtherBreak, timeBetween)
                            if (tempTime == null) {
                                continue
                            }
                            timeOtherBreak = tempTime
                        }
                    }
                    nextEventIsCheckIn = false
                    previousEvent = current_event_name
                    previousTime = current_event_time
                }else{
                    //console.log("Fehler: SOLL isCheckin", nextEventIsCheckIn,  current_event_name, "Voheriges Event", previousEvent , "bei Index", i)
                    isError = true
                }

            }else{ //next event needs to be a check out
                if(checkOutEvents.includes(current_event_name)){
                    if(current_event_name === "check out mit grund"){
                        needsExplanation = true
                    }
                    let timeBetween = calculateTimeDifference(previousTime, current_event_time)
                    //console.log("Zeit dazwischen:", timeBetween, "Zeiten:", previousTime, current_event_time)
                    if(timeBetween == null){
                            continue
                    }
                    tempTime = addTimeSpans(timeWorking, timeBetween)
                    if(tempTime == null){
                        continue
                    }
                    timeWorking = tempTime
                    nextEventIsCheckIn = true
                    previousTime = current_event_time
                    previousEvent = current_event_name
                }else{
                    //console.log("Fehler: SOLL isCheckin", nextEventIsCheckIn,  current_event_name, "Voheriges Event", previousEvent , "bei Index", i)
                    isError = true
                }
            }
            //console.log("Aktuelle Zeiten", "timeWorking", timeWorking, "timeLunch", timeLunch, "timeOtherBreak", timeOtherBreak, "isError", isError)
            //console.log("-----------------")
        }
        if(!nextEventIsCheckIn){    //Timestamps cannot end with CheckIn event -> Must always end with checkout
            isError = true
        }
        return {"timeWorking": timeWorking, "timeLunch": timeLunch, "timeOtherBreak": timeOtherBreak, "needsExplanation" : needsExplanation, "isError": isError}
    }

    const handleClick = () => {
        // Your click event handling logic here
       // console.log('Button clicked!');
        //console.log(addTimeSpans("00:01:51", "01:00:10"))
        if (selectedFile != null) {

            csvReader.readAsText(selectedFile)
            //console.log(getLinesArrayFromCSV(selectedFile))
        }else{
            window.alert("Bitte wählen Sie zuerst eine Datei aus.")
        }
    };


    const handleTestClick = () => {
        //console.log(employeeTimes)
        // console.log(csvInformation)
        // if(csvInformation != null) {
        //     console.log("TEstZeiten", handleTimes2(csvInformation["Christoph.Brandt"]["2023-01-12"]))
        // }
        console.log("Convert", timeToDecimal("03:30:00"))
        };

    function addTimeSpans(timeSpan1: string, timeSpan2: string): string | null {
      const time1Parts = timeSpan1.split(':');
      const time2Parts = timeSpan2.split(':');

      if (time1Parts.length !== 3 || time2Parts.length !== 3) {
        return null; // Invalid time format
      }

      const hour1 = parseInt(time1Parts[0], 10);
      const minute1 = parseInt(time1Parts[1], 10);
      const second1 = parseInt(time1Parts[2], 10);

      const hour2 = parseInt(time2Parts[0], 10);
      const minute2 = parseInt(time2Parts[1], 10);
      const second2 = parseInt(time2Parts[2], 10);

      let totalHours = hour1 + hour2;
      let totalMinutes = minute1 + minute2;
      let totalSeconds = second1 + second2;

      if (totalSeconds >= 60) {
        totalMinutes += Math.floor(totalSeconds / 60);
        totalSeconds %= 60;
      }

      if (totalMinutes >= 60) {
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;
      }

      return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;
    }


    const clickStep = (selectedStep : number) =>{
        if(active === selectedStep){
            return
        }else if(active === 0 && selectedStep === 1){
            return
        }else if(active === 0 && selectedStep === 2){
            return
        }else if(selectedStep === 2 && active !== 2){
            setSelectedDateRangeEndAll("2999-01-01")
            setSelectedDateRangeStartAll("1999-01-01")
        }
        if(selectedStep === 0 && employeeTimes){
            if(!window.confirm("Achtung. Alle vorgenommenen Einstellungen bezüglich des Mitarbeiters und Zeitraums werden verworfen.")){
                return
            }
        }
        setActive(selectedStep)
        if(active === 2 || selectedStep === 2){
            setSelectedEmployeeName(null)
            setSelectedDateRangeEnd("2999-01-01")
            setSelectedDateRangeStart("1999-01-01")
            return
        }
        setSelectedEmployeeName(null)
        setSelectedDateRangeEnd("2999-01-01")
        setSelectedDateRangeStart("1999-01-01")
        setEmployeeTimes(null)
        setSelectedFile(null)
    }

    interface ExcelArray{
        Datum: string,
        "Arbeitszeit in Std": number | string,
        "Mittagspause in Std": number | string,
        "Sonstige Pause in Std": number | string,
        "Fehler im Stempelsatz": string | boolean
    }

    function getTimeExcelArray(times: TimeUsage, dateStart: string, dateEnd: string){
        let excelArray : ExcelArray[] = [{Datum: "", "Arbeitszeit in Std": "", "Mittagspause in Std" : "", "Sonstige Pause in Std" : "", "Fehler im Stempelsatz" : ""}] // Leerzeile nach Header
        let num_rows = 2
        Object.keys(times).map((key) => {
            if(dateStart && dateEnd){
                if(isDateInRange(new Date(dateStart), new Date(dateEnd), new Date(key))){

                    //console.log("yes")
                    num_rows += 1
                    const singleElement = times[key]
                    excelArray.push({Datum: translateAmericanToGermanDate(key), "Arbeitszeit in Std": parseFloat(timeToDecimal(singleElement.timeWorking).toFixed(2)), "Mittagspause in Std" : parseFloat(timeToDecimal(singleElement.timeLunch).toFixed(2)), "Sonstige Pause in Std" : parseFloat(timeToDecimal(singleElement.timeOtherBreak).toFixed(2)), "Fehler im Stempelsatz" : singleElement.isError ? "X" : ""})
                }
            }else{
                const singleElement = times[key]
                num_rows += 1
                excelArray.push({
                    Datum : translateAmericanToGermanDate(key),
                    "Arbeitszeit in Std" : parseFloat(timeToDecimal(singleElement.timeWorking).toFixed(2)),
                    "Mittagspause in Std" : parseFloat(timeToDecimal(singleElement.timeLunch).toFixed(2)),
                    "Sonstige Pause in Std" : timeToDecimal(singleElement.timeOtherBreak).toFixed(2),
                    "Fehler im Stempelsatz" : singleElement.isError ? "X" :""
                })
            }
        })
        excelArray.push({Datum: "", "Arbeitszeit in Std": "", "Mittagspause in Std" : "", "Sonstige Pause in Std" : "", "Fehler im Stempelsatz" : ""})       //Leerzeile vor Summe
        excelArray.push({Datum: "Summe:","Arbeitszeit in Std": `=SUMME(B2:B${num_rows})`, "Mittagspause in Std":`=SUMME(C2:C${num_rows})`, "Sonstige Pause in Std": `=SUMME(D2:D${num_rows})`, "Fehler im Stempelsatz" :""})
        excelArray.push({Datum: "", "Arbeitszeit in Std": "", "Mittagspause in Std" : "", "Sonstige Pause in Std" : "", "Fehler im Stempelsatz" : ""})       //Leerzeile vor Summe
        excelArray.push({Datum: "", "Arbeitszeit in Std": "", "Mittagspause in Std" : "", "Sonstige Pause in Std" : "", "Fehler im Stempelsatz" : ""})       //Leerzeile vor Summe
        return excelArray
    }

    function timeToDecimal(timeString: string): number {
        const [hours, minutes, seconds] = timeString.split(':').map(Number);
        const decimalValue = hours + minutes / 60 + seconds / 3600;
        return decimalValue;
      }

    interface stampsExcelArray{
        Name : string,
        Zeitraum: string,
        Datum : string,
        Uhrzeit: string,
        Stempel: string,
        "Arbeitszeit in Std": string | number,
        "Mittagspause in Std": string | number,
        "Sonstige Pause in Std": string | number,
        "Stempelsatz fehlerhaft": string | number

    }


    function getStampsExcelArray(employeeName: string, stamps: EmployeeEventTimes, times: TimeUsage, dateStart: string, dateEnd: string){


        let num_rows = 1
        let stampsExcelArray : stampsExcelArray[] = []
        let dateRange =""
        if(dateStart === "1999-01-01") {
            dateRange = "Kein Zeitraum ausgewählt"
        }else{
            dateRange = `${translateAmericanToGermanDate(selectedDateRangeStart)} - ${translateAmericanToGermanDate(selectedDateRangeEnd)}`
        }
        stampsExcelArray.push({Name: employeeName, Zeitraum: dateRange, Datum : "", Stempel : "", Uhrzeit : "", "Arbeitszeit in Std" : "", "Mittagspause in Std":"", "Sonstige Pause in Std":"", "Stempelsatz fehlerhaft":"" })
        Object.keys(stamps).map((key) => {
            const singleElement = stamps[key]
            if(isDateInRange(new Date(dateStart), new Date(dateEnd), new Date(key))) {

                num_rows += 1
                stampsExcelArray.push({ Name: "", Zeitraum: "", Datum : "", Stempel : "", Uhrzeit : "", "Arbeitszeit in Std" : "", "Mittagspause in Std":"", "Sonstige Pause in Std":"", "Stempelsatz fehlerhaft":"" })
                for (let i = 0; i < stamps[key].length; i++) {
                    num_rows += 1
                    if (i === 0) {
                        stampsExcelArray.push({
                            Name: "",
                            Zeitraum:"",
                            Datum : translateAmericanToGermanDate(key),
                            Stempel : singleElement[i].name,
                            Uhrzeit : singleElement[i].time,
                            "Arbeitszeit in Std" : parseFloat(timeToDecimal(times[key].timeWorking).toFixed(2)),
                            "Mittagspause in Std": parseFloat(timeToDecimal(times[key].timeLunch).toFixed(2)),
                            "Sonstige Pause in Std": parseFloat(timeToDecimal(times[key].timeOtherBreak).toFixed(2)),
                            "Stempelsatz fehlerhaft": times[key].isError ? "fehlerhaft" : ""
                        })
                    } else {
                        stampsExcelArray.push({
                            Name: "",
                            Zeitraum:"",
                            Datum : "",
                            Stempel : singleElement[i].name,
                            Uhrzeit : singleElement[i].time,
                            "Arbeitszeit in Std" : "",
                            "Mittagspause in Std":"",
                            "Sonstige Pause in Std":"",
                            "Stempelsatz fehlerhaft":""
                        })
                    }
                }
            }
        })
        stampsExcelArray.push({
            Name: "",
            Zeitraum:"",
            Datum : "",
            Stempel : "",
            Uhrzeit : "",
            "Arbeitszeit in Std": "",
            "Mittagspause in Std":"",
            "Sonstige Pause in Std":"",
            "Stempelsatz fehlerhaft":""
        })
        stampsExcelArray.push({
            Name: "",
            Zeitraum:"",
            Datum : "",
            Stempel : "",
            Uhrzeit : "",
            "Arbeitszeit in Std": "______________",
            "Mittagspause in Std":"______________",
            "Sonstige Pause in Std":"______________",
            "Stempelsatz fehlerhaft":""
        })
        stampsExcelArray.push({
            Name: "",
            Zeitraum:"",
            Datum : "SUMME:",
            Stempel : "",
            Uhrzeit : "",
            "Arbeitszeit in Std": `=SUMME(F2:F${num_rows})`,
            "Mittagspause in Std": `=SUMME(G2:G${num_rows})`,
            "Sonstige Pause in Std": `=SUMME(H2:H${num_rows})`,
            "Stempelsatz fehlerhaft":""
        })
        return stampsExcelArray
    }

    function saveAsExcel(){
        //console.log("Klicked")
        if(!employeeTimes || !selectedEmployeeName  || !csvInformation) return
        //console.log("Data", employeeTimes[selectedEmployeeName])
        const singleEmployeeTimes : ExcelArray[] = getTimeExcelArray(employeeTimes[selectedEmployeeName], selectedDateRangeStart, selectedDateRangeEnd)

        if(selectedDateRangeStart != "1999-01-01"){
            singleEmployeeTimes.push({Datum:selectedEmployeeName, "Arbeitszeit in Std": `${translateAmericanToGermanDate(selectedDateRangeStart)} - ${translateAmericanToGermanDate(selectedDateRangeEnd)}`, "Mittagspause in Std":"", "Sonstige Pause in Std": "", "Fehler im Stempelsatz":"" })
        }else{
            singleEmployeeTimes.push({Datum:selectedEmployeeName, "Arbeitszeit in Std": "", "Mittagspause in Std":"", "Sonstige Pause in Std": "", "Fehler im Stempelsatz":"" })
        }
        const singeEmployeeStamps = getStampsExcelArray(selectedEmployeeName, csvInformation[selectedEmployeeName], employeeTimes[selectedEmployeeName], selectedDateRangeStart, selectedDateRangeEnd)


        const worksheetStamps = XLSX.utils.json_to_sheet(singeEmployeeStamps);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheetStamps, 'Zeiten');

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        const filename = selectedEmployeeName.replace(".", " ") + '_Zeiten.xlsx';
        saveAs(blob, filename); // Assuming you have a function like saveAs to download the blob
    }

    function saveAllAsExcel(){
        if(!employeeTimes || !csvInformation) return
        const workbook = XLSX.utils.book_new();
        for (let [employeeName, oneEmployeeTimes] of Object.entries(employeeTimes)) {
            const singleEmployeeTimes  = getStampsExcelArray(employeeName, csvInformation[employeeName], oneEmployeeTimes, selectedDateRangeStartAll, selectedDateRangeEndAll)
            const worksheet = XLSX.utils.json_to_sheet(singleEmployeeTimes);
            XLSX.utils.book_append_sheet(workbook, worksheet, employeeName.replace(".", " "));


        }
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
        let filename
        if(selectedDateRangeStartAll != "1999-01-01"){
             filename = `Alle Mitarbeiter_${selectedDateRangeStartAll} bis ${selectedDateRangeEndAll}.xlsx`;
        }else{
             filename = "Alle Mitarbeiter_gesamter Zeitraum"
        }
        saveAs(blob, filename); // Assuming you have a function like saveAs to download the blob
    }


    function renderUserEmployeeAndDateSelect() {
        if (employeeTimes && allEmployeeNames) {
            return (

                <div className={classes.container2Columns}>
                    <div className={classes.singleColumn}>
                        <Group position={"right"}>
                            <SelectComponent names={allEmployeeNames} changeName={setSelectedEmployeeName}
                                             currentName={selectedEmployeeName}/>
                        </Group>
                    </div>

                    <div className={classes.singleColumn}>

                        <Group position={"left"}>
                            <DateRangePicker selectDateStart={setSelectedDateRangeStart}
                                             selectDateEnd={setSelectedDateRangeEnd}/>
                        </Group>
                    </div>
                </div>

            )
        }else{
            return (
                <p  className={classes.textCenter}>Bitte führen sie zuerst die Auswertung der CSV-Datei im ersten Schritt durch</p>
            )
        }
    }

    function renderEmployeeTimesTable(){
        if(employeeTimes && csvInformation) {
            if (selectedEmployeeName) {
                    return (
                        <div>
                            <TableEmployeeTimeOverview times={employeeTimes} events={csvInformation} name={selectedEmployeeName}
                                                       dateStart={selectedDateRangeStart}
                                                       dateEnd={selectedDateRangeEnd}/>
                            <Button className={classes.btnExportAsExcel} onClick={saveAsExcel}>Als Excel-Datei exportieren</Button>

                        </div>
                    )
            }else{
                return (
                        <p  className={classes.textCenter}>Bitte wählen Sie einen Mitarbeiter aus, um Zeiten und Stempelsätze einzusehen.</p>
                )
            }
        }
    }


    return (
        <div className="App">
            <div className={classes.body}>

                <Stepper active={active} onStepClick={clickStep} breakpoint="sm">
                    <Stepper.Step label="Schritt 1" description="Datei hochladen">
                        <Group position={"center"}>
                        <div className={classes.stepperContentFileInput}>
                            <FileInput
                                onChange={handleFileChange}
                                placeholder="Datei auswählen..."
                                label="Zeiterfassung als .log oder .csv Datei"
                                accept={".csv,.log"}
                                icon={<IconFileTypeCsv/> }
                            />
                            <Group position={"left"} className={classes.buttonGroup}>
                                <Button className={classes.button} onClick={handleClick}>Auswertung starten</Button>
                                {/* <Button onClick={handleTestClick}>Zum Testen</Button> */}
                            </Group>
                        </div>
                            </Group>
                    </Stepper.Step>
                    <Stepper.Step label="Schritt 2" description="Zeiten der Mitarbeiter exportieren">
                            <div className={classes.stepperContent}>
                        <Tabs defaultValue="gallery">

                            <Tabs.List>
                            <Tabs.Tab value="gallery" icon={<IconUser size="0.8rem" />}>Einzelner Mitarbeiter</Tabs.Tab>
                            <Tabs.Tab value="messages" icon={<IconUsers size="0.8rem" />}>Alle Mitarbeiter</Tabs.Tab>
                            </Tabs.List>
                    
                            <Tabs.Panel value="gallery" pt="xs">
                                <div className={classes.tabsContent}>
                                {renderUserEmployeeAndDateSelect()}
                            </div>
                            <div className={classes.employeeTimeTable}>
                                {renderEmployeeTimesTable()}
    {/*                            <Group position={"left"}>
                                    <Button className={classes.uploadNewCsvButton} onClick={uploadNewCsv}  >Neue CSV-Datei erfassen</Button>
                                </Group>*/}
                            </div>
                            </Tabs.Panel>
                    
                            <Tabs.Panel value="messages" pt="xs">

                                <div className={classes.tabsContent}>
                                    <Group position={"center"}>

                                    <DateRangePicker selectDateStart={setSelectedDateRangeStartAll} selectDateEnd={setSelectedDateRangeEndAll}/>

                                    </Group>
                                        <Group position={"center"}>
                                        <Button onClick={saveAllAsExcel} className={classes.btnExportAllAsExcel}> Alle Mitarbeiterzeiten als Excel Datei
                                            exportieren</Button>
                                            </Group>
                                </div>
                            </Tabs.Panel>
                    
                        </Tabs>
                        
                                </div>
                    </Stepper.Step>
                    <Stepper.Completed>
                        Completed, click back button to get to previous step
                    </Stepper.Completed>
                </Stepper>

            </div>


            {/*{employeeTimes ? <TableEmployeeTimeOverview times={employeeTimes} name={"Christoph.Brandt"} dateStart={"2023-01-29"} dateEnd={"2023-05-23"}/> : null}*/}
{/*            <div className={classes.container2Columns}>
                <div className={classes.singleColumn}>
                    <Group position={"right"}>
                        <SelectComponent names={["test1", "test2"]} changeName={setSelectedEmployeeName}/>
                    </Group>

                </div>
                <div className={classes.singleColumn}>
                    <DateRangePicker/>
                </div>
            </div>*/}


        </div>
    );
}

export default Dashboard;
