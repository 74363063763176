import React, { useState } from 'react';
import { Group } from '@mantine/core';
import { DatePicker } from '@mantine/dates';

interface DatePickerProps{
  selectDateStart: React.Dispatch<React.SetStateAction<string>>
  selectDateEnd: React.Dispatch<React.SetStateAction<string>>
}

function DateRangePicker({selectDateStart, selectDateEnd} : DatePickerProps) {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);

    const handleChangeSelectName = (selectedOption : [Date | null, Date | null]) => {
      //console.log("selected", selectedOption)
      setValue(selectedOption)
      if(selectedOption[0] && selectedOption[1]) {
        //console.log(selectedOption[0].toLocaleDateString("de-DE"))
        selectDateStart(selectedOption[0].toLocaleDateString("en-CA"))
        selectDateEnd(selectedOption[1].toLocaleDateString("en-CA"))
      }else if(!selectedOption[0] && !selectedOption[1]){ // if user deselects -> show all data
        selectDateStart("1999-01-01")
        selectDateEnd("2999-01-01")
      }/*else if(selectedOption[0] && !selectedOption[1]){
        selectDateStart(selectedOption[0].toLocaleDateString("en-CA"))
        selectDateEnd(selectedOption[0].toLocaleDateString("en-CA"))
      }else if(!selectedOption[0] && selectedOption[1]){
        selectDateStart(selectedOption[1].toLocaleDateString("en-CA"))
        selectDateEnd(selectedOption[1].toLocaleDateString("en-CA"))
      }*/
  };



  return (
      <DatePicker type="range" value={value} onChange={handleChangeSelectName} />
  );
}

export default DateRangePicker