import {Group, Table, Tooltip} from '@mantine/core';
import {TimeUsage, EmployeesTimeUsage, EmployeeTimes, EventData,EmployeeEventTimes} from "../pages/Dashboard"
import {Button} from "@mantine/core";
import classes from "./TableEmployeeTimeOverview.module.css"
import ModalComponent from "./ModalComponent";
import {IconAlertCircle, IconUserQuestion} from "@tabler/icons-react"

import SelectStamp from "./SelectStamp";

interface TableProps{
    stamps : EventData[];
}



function TableAlterStamps({ stamps } : TableProps) {

    const rows = stamps.map((event, index) => {

            const time = event.time
            const event_name = event.name
            return (
                <tr key={time}>
                    <td>{time}</td>
                    <td>{event_name}</td>
                </tr>
            );

        })


    return (
        <div className={classes.tablediv}>
            <Table highlightOnHover>
                <thead>
                <tr className={classes.thead}>
                    <th className={classes.th}>Uhrzeit</th>
                    <th className={classes.th}>Stempel</th>
                </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </div>
    );


}


export default TableAlterStamps