import { Select } from '@mantine/core';
import {EmployeesTimeUsage} from "../pages/Dashboard";
import classes from "./SelectComponent.module.css"
import React from "react"
import {IconUser} from "@tabler/icons-react"



  interface SelectProps{
    names: string[]
    changeName: React.Dispatch<React.SetStateAction<string | null>>
    currentName: string | null
}



function SelectComponent({ names, changeName } : SelectProps) {

    const handleChangeSelectName = (selectedOption : string) => {
      console.log(selectedOption)

      changeName(selectedOption)
  };



  return (
    <Select
        className={classes.select}
      label="Mitarbeiter"
      placeholder="Auswählen ..."
      searchable
      nothingFound="Mitarbeiter nicht gefunden"
      data={names.sort()}
        onChange={handleChangeSelectName}
        icon={<IconUser size="1.1rem"/>}
    />
  );
}

export default SelectComponent