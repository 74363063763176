import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Layout from "./components/Layout";
import Einstellungen from "./pages/Einstellungen";



function App() {

  return (
      <div>

          <BrowserRouter>
              <Layout>
                  <Routes>
                      <Route path="/" element={<Dashboard/>}/>
                      <Route path="/einstellungen" element={<Einstellungen/>}/>
                  </Routes>
              </Layout>

          </BrowserRouter>
      </div>
  );
}

export default App;
