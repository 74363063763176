import {Link} from "react-router-dom"
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {Button} from "@mantine/core";

import React, {ChangeEvent, useState} from 'react';


function Einstellungen() {

interface ExcelData {
  name: string;
  age: string | number;
  email: string;
}



function save(){
    console.log("Klicked")
    const data: ExcelData[] = [
      { name: 'John Doe', age: 30, email: 'john@example.com' },
      { name: 'Jane Smith', age: 25, email: 'jane@example.com' },
        {name: "Summe", age: "=SUMME(B2:B3)", email: "troll"}
      // ... more data
    ];
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
    const filename = 'data.xlsx';
    saveAs(blob, filename); // Assuming you have a function like saveAs to download the blob
}

  return (
      <div className="App">
        <p>Einstellungen</p>
          <Link to={"/"}> Test </Link>
          <Button onClick={save}>Test</Button>

      </div>
  );
}

export default Einstellungen;
