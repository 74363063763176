import {
  createStyles,
  Menu,
  Center,
  Header,
  Container,
  Group,
  Button,
  Burger,
  rem,
} from '@mantine/core';
import { Link, NavLink } from "react-router-dom";
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { MantineLogo } from '@mantine/ds';
import  Logo  from "../images/Logo.png"
import BrigitteGroßeLogo from "../images/BrigitteGroßeLogo2.png"
import classesCss from "./HeaderAction.module.css"

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  link: {
    display: 'block',
    lineHeight: 1,
    marginRight: "8rem",
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkLabel: {
    marginRight: rem(5),
  },
}));

export interface HeaderLink {
  link: string;
  label: string;
  links?: { link: string; label: string }[];
}

interface HeaderActionProps {
  links: HeaderLink[];
}

function HeaderAction({ links }: HeaderActionProps) {
  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={"menuitem" + item.label}><Link to={item.link}>{item.label}</Link></Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu key={"menu" + link.label} trigger="hover" transitionProps={{ exitDuration: 0 }} withinPortal>
          <Menu.Target>
            <Link
              to={link.link}
              className={classes.link}
              key={link.label}
            >
              <Center>
                <span className={classes.linkLabel} key={"span"+link.label}>{link.label}</span>
                <IconChevronDown size={rem(12)} stroke={1.5} />
              </Center>
            </Link>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }
    return (
      <Link to={link.link} className={classes.link} key={link.label}>
        {link.label}
      </Link>
    );
  });

  return (
    <Header height={HEADER_HEIGHT} sx={{ borderBottom: 0 }} mb={120}>
      <Container className={classes.inner} fluid>
        <Group>
          <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
          {/*<MantineLogo size={28} />      svg logo*/}
          <img src={BrigitteGroßeLogo} alt="Simplicius Logo" className={classesCss.logo}/>
        </Group>
        <Group spacing={5} className={classes.links}>
          {items}
        </Group>
        {/*<Button radius="xl" h={30}>
          Abmelden
        </Button>*/}
        <p></p>
      </Container>
    </Header>
  );
}

export default HeaderAction;