import { useDisclosure } from '@mantine/hooks';
import { Modal, Group, Button } from '@mantine/core';
import {EmployeeTimes} from "../pages/Dashboard";
import TableAlterStamps from "./TableAlterStamps";
import classes from "./ModalComponents.module.css"

interface ModalProps{
    events: EmployeeTimes
    name: string
    date: string
}

function ModalComponent({events, name, date}:ModalProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const nameReadable = name.replace(".", " ")


  function btnClicked(){
      console.log(events[name][date])
      open()
  }

    function saveBtnClicked() {
        console.log(events[name][date])
        events[name][date] = [{ "time" : "09:30:00", "name" : "Check In & Türöffner" }, {
            "time" : "18:30:00",
            "name" : "Check Out"
        }]
        console.log(events[name][date])

    }

      function translateAmericanToGermanDate(americanDate : string):string{
        const dateParts = americanDate.split('-');
        if (dateParts.length !== 3) {
            return "01.01.1999"; // Invalid date format
        }

        const year = dateParts[0];
        const month = dateParts[1];
        const day = dateParts[2];

        return `${day}.${month}.${year}`;
    }

  return (
    <>
      <Modal opened={opened} onClose={close}   centered>
        {/* Modal content */}
          <div className={classes.modalBody}>
              <TableAlterStamps stamps={events[name][date]}/>
              <div className={classes.buttonDiv}>
                  <Button onClick={close}>Zurück</Button>
              </div>

          </div>
      </Modal>

      <Group position="center">
        <Button onClick={btnClicked}>Stempelsätze</Button>
      </Group>
    </>
  );
}

export default ModalComponent